// https://codepen.io/rebosante/pen/eENYBv
// HOW TO USE
// EXAMPLE
// function scrolling() {
//      let elem = document.getElementsByClassName("partner-container"); -> get destination element
//      let topPos = elem[0].offsetTop;
//      scrollTo(document.scrollingElement, topPos - 30, 600); -> call scrollTo function
//   }

export function scrollTo(element, to, duration) {
  const start = element.scrollTop,
    change = to - start,
    increment = 20;

  let currentTime = 0;

  //t = current time
  //b = start value
  //c = change in value
  //d = duration
  const easeInOutQuadMath = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const animateScroll = function () {
    currentTime += increment;
    const val = easeInOutQuadMath(currentTime, start, change, duration);
    element.scrollTop = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
}

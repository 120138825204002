import CryptoJS from "crypto-js";

export const encrypt = (obj) => {
  return CryptoJS.AES.encrypt(JSON.stringify(obj), process.env.SECRET_KEY, {
    keySize: 128 / 8,
    iv: process.env.SECRET_KEY,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString();
};

export const decrypt = (data) => {
  return JSON.parse(
    CryptoJS.enc.Utf8.stringify(
      CryptoJS.AES.decrypt(data, process.env.SECRET_KEY, {
        keySize: 128 / 8,
        iv: process.env.SECRET_KEY,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })
    )
  );
};

import React from "react";

type SelectProps = {
  data?: any;
  onChange?: any;
  onBlur?: any;
  value?: string;
  title?: string;
  star?: boolean;
  errMsg?: string;
  disabled?: boolean;
  keyModifier?: number;
  name?: string;
  formTitleFontSize?: string;
  required?: boolean;
  marginMobile?: string;
};

export const Select = ({
  data,
  onChange,
  onBlur,
  value,
  title,
  star,
  errMsg,
  disabled = false,
  keyModifier,
  name,
  formTitleFontSize,
  required = false,
  marginMobile
}: SelectProps) => {
  return (
    <React.Fragment>
      <div className="form-input">
        <div className="input-title">
          {title}
          {star ? <span style={{ color: "red" }}> *</span> : ""}
        </div>
        <div className="input-select-wrapper">
          <select
            className={"input-select" + (errMsg ? " input--error" : "")}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            name={name}
            required={required}
          >
            {data && Array.isArray(data)
              ? data.map((i) => (
                  <option key={i + keyModifier} value={i}>
                    {i}
                  </option>
                ))
              : Object.keys(data).map((value) => (
                  <option key={value + keyModifier} value={data[value]}>
                    {value}
                  </option>
                ))}
          </select>
        </div>
        <div className="error-msg">{errMsg}</div>
      </div>
      <style jsx>{`
        .error-msg {
          color: var(--color-red);
          text-align: right;
          font-size: 12px;
        }
        .form-input {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin: 10px 0 20px 0;
        }
        .input-select-wrapper {
          display: flex;
          position: relative;
        }
        .input-title {
          font-size: ${formTitleFontSize ? formTitleFontSize : "16px"};
          color: #404040;
          line-height: 19px;
          font-weight: Bold;
          margin-bottom: 8px;
        }
        .input-select {
          border: 0;
          min-width: 0;
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          color: #000;
          padding: 0.75rem 1.5rem;
          -webkit-appearance: unset;
          border: 1px solid #fff;
          font-size: 16px;
          box-shadow: 0 2px 4px 0 rgba(64, 64, 64, 0.4);
          border-radius: 8px;
          background: url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
            no-repeat;
          -webkit-appearance: none;
          background-position-y: 1rem;
          background-position-x: 98%;
        }
        .input--error {
          border: 1px solid var(--color-red);
        }
        .input-select:disabled {
          background-color: #f4f4f4;
          cursor: not-allowed;
          border: none;
          color: #7b7b7b;
        }
        @media only screen and (max-width: 600px) {
          .input-title {
            font-size: 12px;
          }
          .form-input {
            margin: ${marginMobile ? marginMobile : ""};
          }
        }
      `}</style>
    </React.Fragment>
  );
};
